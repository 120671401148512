<template>

<div>

  <task-entry :taskSection="1" />

  <tasks :taskSection="1" />

</div>

</template>

<script>
import Tasks from '@/components/Tasks';
import TaskEntry from '@/components/TaskEntry';

export default {
  name: 'TasksList',
  components: {
    Tasks,
    TaskEntry,
  },
};
</script>

<style scoped>

</style>
